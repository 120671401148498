import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionB41 = () => {
  return (
    <Box
      sx={{
        direction: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50rem" }}>
        <TextP>
          企业邮箱就是公司邮箱，可以由公司对邮箱及其账户进行统一管理。企业邮箱是以你对企业名称或者品牌名称为后缀的邮箱，所以企业邮箱有以下特点：
        </TextP>
        <StaticImage
          src="../../../assets/images/domain/e1.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP>
          一、企业邮箱的域名就是邮箱后缀，公司负责管理邮箱的人管理邮箱账户，账户名可以自己定义，不像个人邮箱注册不到想要的账号，注册了也没办法修改
        </TextP>
        <StaticImage
          src="../../../assets/images/domain/e2.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP>二、大容量，能存储公司里所有的邮件</TextP>
        <StaticImage
          src="../../../assets/images/domain/e3.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP> 三、通讯录管理。可以将企业内部通讯录进行统一管理。</TextP>
        <StaticImage
          src="../../../assets/images/domain/e4.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP>
          四、办公管理。企业邮箱相当于一个小的办公管理平台，所有的子账号都由负责人统一管理，在平台里还可以建其他的子账号、部门、群组，让团队协作效率很高
        </TextP>
        <TextP>
          从而通过企业邮箱，可以为您的企业提供更好的形象，打破信任危机，促进订单成交，提升企业的品牌力。
        </TextP>
      </Box>
    </Box>
  )
}

export default SectionB41

const HotBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0;
`

const TextP = styled.p`
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
`
