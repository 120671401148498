import React from "react"
import { navigate } from "gatsby"

// material-ui
import Divider from "@mui/material/Divider"

// third-party
import {
  useSpring,
  useChain,
  animated,
  useSpringRef,
  config,
} from "react-spring"

// styles
import styled from "@emotion/styled"

const SliderMenu = ({ title, href, isCurrent, divider }) => {
  const [open, set] = React.useState(false)
  const toggle = () => {
    set(value => !value)
  }

  const springApi1 = useSpringRef()
  const { y } = useSpring({
    ref: springApi1,
    to: {
      y: open ? "-48px" : "0px",
    },
  })

  const springApi2 = useSpringRef()
  const props = useSpring({
    ref: springApi2,
    strokeWidth: open ? "3px" : "6px",
    strokeDashoffset: open ? 0 : -268,
    strokeDasharray: open ? "228, 0" : "100, 456",
    stroke: open ? "rgb(83, 140, 192)" : "rgb(116, 60, 127)",
    config: config.molasses,
  })

  useChain(open ? [springApi1, springApi2] : [springApi2, springApi1], [0, 0.3])

  return (
    <React.Fragment>
      <Container>
        <AnimatedBox isCurrent={isCurrent}>
          <animated.div style={{ y }}>
            <TextBox>{title}</TextBox>
            <animated.svg width="180" height="48" viewBox="0 0 180 48">
              <defs>
                <filter id="blur-2px">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                </filter>
              </defs>
              <animated.rect
                width="180"
                height="48"
                strokeWidth={props.strokeWidth}
                stroke={props.stroke}
                strokeDasharray={props.strokeDasharray}
                strokeDashoffset={props.strokeDashoffset}
                filter="url(#blur-2px)"
                fill="#673ab7"
              ></animated.rect>
              <animated.text
                x="50%"
                y="50%"
                fill="rgb(255,255,255)"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="1.1rem"
              >
                {title}
              </animated.text>
            </animated.svg>
          </animated.div>
        </AnimatedBox>
        <MarkBox
          onMouseEnter={toggle}
          onMouseLeave={toggle}
          onClick={() => navigate(href)}
        />
      </Container>
      {divider ? (
        <Divider orientation="vertical" variant="middle" flexItem />
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default SliderMenu

const AnimatedBox = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${({ isCurrent }) =>
    isCurrent ? "#673ab7" : "transparent"};
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  z-index: 1;
`

const MarkBox = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 12;
`

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
`

const Container = styled.div`
  position: relative;
  width: 180px;
  height: 48px;
`
