import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionB51 = () => {
  return (
    <Box
      sx={{
        direction: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50rem" }}>
        <StaticImage
          src="../../../assets/images/domain/a1.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP>
          2019年5月13日正式发布《信息安全技术网络安全等级保护基本要求》（即“网络安全等级保护技术2.0”，简称“等保2.0”）。网络安全等级保护制度是国家信息安全保障工作的基本制度、基本国策和基本方法。网络安全等级保护制度将从云计算、物联网、工控系统、移动互联、大数据安全五个方面进行安全规范要求，相应的标准体系有待升级扩充，以应对新形势下的安全挑战。拟将出台《网络安全等级保护基本要求》、《网络安全等级保护安全设计技术要求》、《网络安全等级保护测评要求》等网络安全等级保护管理规范和技术标准。
        </TextP>
        <TextP>
          目前HTTPS是现行网络架构下最安全的解决方案。基于SSL加密层，用户可以将网站由HTTP切换到HTTPS，从而保证网络数据传输的安全。有了HTTPS加密，可以防止网站流量劫持，保护用户隐私，还可以保障企业的利益不受损害。为用户隐私保驾护航，将网络攻击风险扼杀在摇篮。
        </TextP>
      </Box>
    </Box>
  )
}

export default SectionB51

const HotBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0;
`

const TextP = styled.p`
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
`
