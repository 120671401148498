import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionB31 = () => {
  return (
    <Box
      sx={{
        direction: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50rem" }}>
        <StaticImage
          src="../../../assets/images/domain/cloud1.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px auto" }}
          alt="background"
        />
        <TextP>
          云空间是一种网上在线存储（英语：Cloud
          storage）的模式，即把数据存放在通常由第三方托管的多台虚拟服务器，而非专属的服务器上。托管（hosting）公司运营大型的数据中心，需要数据存储托管的人，则通过向其购买或租赁存储空间的方式，来满足数据存储的需求。数据中心营运商根据客户的需求，在后端准备存储虚拟化的资源，并将其以存储资源池（storage
          pool）的方式提供，客户便可自行使用此存储资源池来存放文件或对象。实际上，这些资源可能被分布在众多的服务器主机上。
        </TextP>
      </Box>
    </Box>
  )
}

export default SectionB31

const HotBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0;
`

const TextP = styled.p`
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
`
