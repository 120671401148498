import React from "react"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// third-party
import { useSpring, useChain, animated, useSpringRef } from "react-spring"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionA1 = () => {
  const h = "48px"

  const [open, setToggle] = React.useState(false)

  const toggle = () => {
    setToggle(value => !value)
  }

  const springApi1 = useSpringRef()
  const { y } = useSpring({
    ref: springApi1,
    to: {
      y: open ? `-${h}` : "0px",
    },
  })

  const springApi2 = useSpringRef()
  const props = useSpring({
    ref: springApi2,
    strokeDashoffset: open ? 0 : -268,
    strokeDasharray: open ? "228, 0" : "100, 456",
    stroke: open ? "rgb(250,250,250,0.7)" : "rgb(116, 60, 127)",
  })

  useChain(open ? [springApi1, springApi2] : [springApi2, springApi1], [
    0,
    open ? 0.3 : 0.1,
  ])

  return (
    <RootBox>
      <Container>
        <AnimatedBox>
          <animated.div style={{ y }}>
            <TextBox>企业建站</TextBox>
            <SvgBox height="48" width="180">
              <SvgText x="50%" y="50%" fill="rgb(255,255,255)">
                企业建站
              </SvgText>
              <SvgRect height="48" width="180" />
            </SvgBox>
          </animated.div>
        </AnimatedBox>
        <MarkBox onMouseEnter={toggle} onMouseLeave={toggle} />
      </Container>
      <Container>
        <AnimatedBox>
          <animated.div style={{ y }}>
            <TextBox>企业建站</TextBox>
            <animated.svg width="180" height="48" viewBox="0 0 180 48">
              <animated.text
                x="50%"
                y="50%"
                fill="rgb(255,255,255)"
                css={css`
                  dominant-baseline: middle;
                  text-anchor: middle;
                  font-size: 1.1rem;
                `}
              >
                企业建站
              </animated.text>
              <animated.rect
                width="180"
                height="48"
                strokeWidth="6px"
                stroke={props.stroke}
                strokeDasharray={props.strokeDasharray}
                strokeDashoffset={props.strokeDashoffset}
                fill="transparent"
              ></animated.rect>
            </animated.svg>
          </animated.div>
        </AnimatedBox>
        {/* <MarkBox
          onMouseEnter={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
        /> */}
        <CenterBox>
          <ToggleBtn onClick={toggle}>TOGGLE</ToggleBtn>
        </CenterBox>
      </Container>

      <Container>
        <AnimatedBox>
          <animated.div style={{ y }}>
            <TextBox>企业建站</TextBox>
            <animated.svg width="180" height="48" viewBox="0 0 180 48">
              <defs>
                <filter id="f1" x="0" y="0">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter>
              </defs>
              <animated.text
                x="50%"
                y="50%"
                fill="rgb(255,255,255)"
                css={css`
                  dominant-baseline: middle;
                  text-anchor: middle;
                  font-size: 1.1rem;
                `}
              >
                企业建站
              </animated.text>
              <animated.rect
                filter="url(#f1)"
                width="180"
                height="48"
                strokeWidth="6px"
                stroke={props.stroke}
                strokeDasharray={props.strokeDasharray}
                strokeDashoffset={props.strokeDashoffset}
                fill="transparent"
              ></animated.rect>
            </animated.svg>
          </animated.div>
        </AnimatedBox>
        {/* <MarkBox
          onMouseEnter={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
        /> */}
      </Container>
    </RootBox>
  )
}

export default SectionA1

const AnimatedBox = styled.div`
  position: absolute;
  width: 100%;
  background-color: rgb(116, 60, 127);
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  z-index: 1;
`

const MarkBox = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 12;
`

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
`

const RootBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

const CenterBox = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
`

const ToggleBtn = styled.button`
  margin-top: 24px;

  padding: 8px 16px;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  letter-spacing: 0.1em;
  cursor: pointer;
  color: white;
`

const Container = styled.div`
  position: relative;
  width: 180px;
  height: 170px;
  margin: auto 15px;
`

const SvgBox = styled.svg`
  width: 180px;
  height: 48px;
  display: inline-block;
`
const SvgRect = styled.rect`
  stroke-width: 6px;
  fill: transparent;
  stroke: #009ffd;
  stroke-dasharray: 100 456;
  stroke-dashoffset: -268;
  transition: 1s all ease;
  :hover {
    stroke-dasharray: 10 0;
    stroke-width: 5px;
    stroke-dashoffset: 0;
    stroke: #06d6a0;
    cursor: pointer;
  }
`

const SvgText = styled.text`
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 1.1rem;
`
