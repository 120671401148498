import React from "react"

// third-party
import { useSpring, useChain, animated, useSpringRef } from "react-spring"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SHOW_VALUE = 308

const SectionA13 = () => {
  const [isHide, setHide] = React.useState(true)

  const springApi1 = useSpringRef()
  const { strokeDashoffset } = useSpring({
    ref: springApi1,
    strokeDashoffset: isHide ? SHOW_VALUE : 0,
  })

  const springApi2 = useSpringRef()
  const props = useSpring({
    ref: springApi2,
    strokeDashoffset: isHide ? -268 : 0,
    strokeDasharray: isHide ? "100, 456" : "10, 0",
    strokeWidth: isHide ? "6px" : "5px",
    stroke: isHide ? "#009ffd" : "#06d6a0",
  })

  useChain(
    isHide ? [springApi2, springApi1] : [springApi1, springApi2],
    [0, 0.6]
  )

  function toggle() {
    setHide(value => !value)
  }

  return (
    <RootBox>
      <animated.svg width="180" height="48" viewBox="0 0 180 48">
        <animated.text
          x="50%"
          y="50%"
          fill="rgb(255,255,255)"
          css={css`
            dominant-baseline: middle;
            text-anchor: middle;
            font-size: 1.1rem;
          `}
        >
          企业建站
        </animated.text>
        <animated.rect
          width="180"
          height="48"
          strokeWidth={props.strokeWidth}
          stroke={props.stroke}
          strokeDasharray={props.strokeDasharray}
          strokeDashoffset={props.strokeDashoffset}
          fill="transparent"
        ></animated.rect>
      </animated.svg>

      <svg width="100" viewBox="0 0 100 100">
        <animated.circle
          css={css`
            fill: none;
            stroke: white;
          `}
          cx="50"
          cy="50"
          r="48"
          strokeDasharray={SHOW_VALUE}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <ToggleBtn onClick={toggle}>TOGGLE</ToggleBtn>
    </RootBox>
  )
}

export default SectionA13

const ToggleBtn = styled.button`
  margin-top: 24px;

  padding: 8px 16px;
  border: 1px solid white;
  background: transparent;
  letter-spacing: 0.1em;
  cursor: pointer;
`

const RootBox = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;

  & button {
    color: white;
  }
`
