import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionB21 = () => {
  return (
    <Box
      sx={{
        direction: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50rem" }}>
        <StaticImage
          src="../../../assets/images/domain/beian3.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px 0" }}
          alt="background"
        />
        <TextP>
          网站备案又叫ICP备案，这个备案是提交到通信管理局的，ICP备案时间审批周期3-20工作日。根据《互联网信息服务管理办法》第三条规定互联网信息服务分为经营性和非经营性两类。经营性互联网信息服务，是指通过互联网向上网用户有偿提供信息或者网页制作等服务活动，指的是ICP经营许可证。非经营性互联网信息服务，是指通过互联网向上网用户无偿提供具有公开性、共享性信息的服务活动，这里的非经营性指的就是ICP备案。目前网站服务器在中国大陆的，都需要做ICP备案。
        </TextP>
      </Box>
      <Box sx={{ width: "300px", mt: 10, ml: 8 }}>
        {" "}
        <HotBox>
          <StaticImage
            src="../../../assets/images/domain/beian1.jpg"
            placeholder="blurred"
            layout="constrained"
            width="100"
            height="70"
            imgStyle={{ width: "100", height: "70" }}
            alt="background"
          />
          <div style={{ width: "11rem", padding: "0 1rem" }}>
            <h3 style={{ fontSize: "16px" }}>备案流程</h3>
            <p style={{ fontSize: "12px" }}>
              域名分为一级域名，二级域名，三级域名......
            </p>
          </div>
        </HotBox>
      </Box>
    </Box>
  )
}

export default SectionB21

const HotBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0;
`

const TextP = styled.p`
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
`
