import React from "react"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionA12 = () => {
  return (
    <RootBox>
      <SvgBox key="svg-line">
        <svg
          width="200"
          height="50"
          viewBox="0 0 200 50"
          css={css`
            :hover line {
              stroke-dashoffset: 0;
              cursor: pointer;
            }
          `}
        >
          <line
            css={css`
              transition: all 2s;
            `}
            x1="0"
            y1="0"
            x2="200"
            y2="0"
            strokeWidth="50"
            stroke="red"
            strokeDasharray="200,220"
            strokeDashoffset="220"
          />
        </svg>
      </SvgBox>
      <SvgBox key="svg-circle">
        <svg
          css={css`
            background-color: black;
            :hover circle {
              stroke-dashoffset: 0;
              cursor: pointer;
            }
          `}
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          <circle
            css={css`
              transition: all 2s;
              stroke-dasharray: 314, 314;
              stroke-dashoffset: 314;
            `}
            cx="50"
            cy="50"
            r="50"
            fill="gray"
            strokeWidth="5"
            stroke="red"
          />
        </svg>
      </SvgBox>
      <SvgBox key="svg-rect">
        <svg height="48" width="180">
          <text
            x="50%"
            y="50%"
            fill="rgb(255,255,255)"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="1.1rem"
          >
            企业建站
          </text>
          <rect
            css={css`
              stroke-width: 6px;
              fill: transparent;
              stroke: #009ffd;
              stroke-dasharray: 100 456;
              stroke-dashoffset: -268;
              transition: 1s all ease;
              :hover {
                stroke-dasharray: 10 0;
                stroke-width: 5px;
                stroke-dashoffset: 0;
                stroke: #06d6a0;
                cursor: pointer;
              }
            `}
            height="48"
            width="180"
          />
        </svg>
      </SvgBox>
      <SvgBox>
        <svg height="48" width="180">
          <defs>
            <filter id="f1" x="0" y="0">
              <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
            </filter>
          </defs>
          <rect
            filter="url(#f1)"
            css={css`
              stroke-width: 6px;
              fill: yellow;
              stroke: #009ffd;
              stroke-dasharray: 100 456;
              stroke-dashoffset: -268;
              transition: 1s all ease;
              :hover {
                stroke-dasharray: 10 0;
                stroke-width: 8px;
                stroke-dashoffset: 0;
                stroke: #06d6a0;
                cursor: pointer;
              }
            `}
            height="48"
            width="180"
          />
          <text
            x="50%"
            y="50%"
            fill="rgb(255,0,0)"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="1.1rem"
          >
            企业建站
          </text>
        </svg>
      </SvgBox>
    </RootBox>
  )
}

export default SectionA12

const SvgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  width: 250px;
  height: 180px;
  background-color: rgb(116, 60, 127);
`

const RootBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(192, 192, 192);
  padding: 15px;
`
