import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"

// styles
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const SectionB11 = () => {
  return (
    <Box
      sx={{
        direction: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50rem" }}>
        {/* <h3 style={{ textAlign: "center" }}>什么是企业域名</h3> */}
        <TextP>要想了解什么是“企业域名”，就需要先了解什么是“域名”。</TextP>
        <StaticImage
          src="../../../assets/images/domain/d4.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px 0" }}
          alt="background"
        />
        <TextP>
          我们在访问某个网站时，需要输入相对应的“网址”，其顾名思义就是“网络上的地址”，以篆文科技为例，篆文科技的网址是“zw027.com”，通过这串字符组合，就可以访问武汉篆文信息科技有限公司首页。
          而篆文科技的域名“zw027.com”则是被包含在网址当中，通过域名“zw027.com”也就可以访问武汉篆文信息科技有限公司首页。
        </TextP>
        <TextP>
          域名像商标一样是互联网上的标识；他是国际通行，在互联网是唯一的，谁先注册，谁就先拥有使用权；他是互联网上“指路的门牌号码”，通过这个门牌号码，能够找到相应的服务器，或是相应的网络系统。
        </TextP>
        <StaticImage
          src="../../../assets/images/domain/d5.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ margin: "16px 0" }}
          alt="background"
        />
        <TextP>
          企业域名则是企业为方便用户、加强互联网品牌建设而注册或收购的一张“网络名片”，所以不少具有一定规模以及影响力的知名企业，会为了一个与自身品牌相契合的域名“豪掷千金”。
        </TextP>
        <TextP>
          在互联网上，企业拥有一个好的域名，就相当于拥有一个好的“门牌号码”，互联网用户的好奇心以及对优质信息的敏感度极高，所以当企业为自己的网站设置了一个不错的“门牌号码”，也会吸引更多用户前来访问，从而加深品牌的影响力以及扩大品牌的知名度。
        </TextP>
        <TextP>
          所以一个优质企业域名对于企业而言，不仅仅是互联网一个漂亮的“门面”，更是一把开启公司品牌甚至企业形象的“流量钥匙”。我们在选择企业域名的时候，最好是与自己的网站内容或者企业品牌相关的，对于大部分的企业来说应该选择与自己经营内容或品牌相关的域名。域名越方便输入越好。另外当用户第一次听到或者看到你的域名时候，最好能让他一眼就看出来你企业的主要业务内容。
        </TextP>
        {/* <h3 style={{ textAlign: "center" }}>域名注册流程：</h3> */}
        <TextP> 域名注册流程：</TextP>
        {/* <h3 style={{ textAlign: "center" }}>我们提供的服务：</h3> */}
        <TextP> 我们提供的服务：</TextP>
      </Box>
      <Box sx={{ width: "300px", mt: 10, ml: 8}}>
        <HotBox>
          <StaticImage
            src="../../../assets/images/domain/d1.jpg"
            placeholder="blurred"
            layout="constrained"
            width="100"
            height="70"
            imgStyle={{ width: "100", height: "70" }}
            alt="background"
          />
          <div style={{ width: "11rem", padding: "0 1rem" }}>
            <h3 style={{ fontSize: "16px" }}>域名的分类</h3>
            <p style={{ fontSize: "12px" }}>
              域名分为一级域名，二级域名，三级域名......
            </p>
          </div>
        </HotBox>
        <HotBox>
          <StaticImage
            src="../../../assets/images/domain/d2.jpg"
            placeholder="blurred"
            layout="constrained"
            width="100"
            height="70"
            alt="background"
          />
          <div style={{ width: "11rem", padding: "0 1rem" }}>
            <h3 style={{ fontSize: "16px" }}>如何才能注册好域名</h3>
            <p style={{ fontSize: "12px" }}>域名分，三级域名......</p>
          </div>
        </HotBox>
        <HotBox>
          <StaticImage
            src="../../../assets/images/domain/d3.jpg"
            placeholder="blurred"
            layout="constrained"
            width="100"
            height="70"
            alt="background"
          />
          <div style={{ width: "11rem", padding: "0 1rem" }}>
            <h3 style={{ fontSize: "16px" }}>如何获取免费域名</h3>
            <p style={{ fontSize: "12px" }}>
              域名分为一级域名，二级域名，三级域名......
            </p>
          </div>
        </HotBox>
        {/* <ul>
          <li>域名的分类</li>
          <li>如何才能注册好域名</li>
          <li>如何获取免费域名</li>
          <li>怎样辨别企业域名好不好</li>
          <li>域名的分类</li>
          <li>域名的分类</li>
        </ul> */}
      </Box>
    </Box>
  )
}

export default SectionB11

const HotBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0;
`

const TextP = styled.p`
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
`
