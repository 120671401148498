import React from "react"
import SectionA11 from "../screens/templates/website/SectionA11"
import SectionA12 from "../screens/templates/website/SectionA12"
import SectionA13 from "../screens/templates/website/SectionA13"
import SectionB11 from "../screens/templates/website/SectionB11"
import SectionB21 from "../screens/templates/website/SectionB21"
import SectionB31 from "../screens/templates/website/SectionB31"
import SectionB41 from "../screens/templates/website/SectionB41"
import SectionB51 from "../screens/templates/website/SectionB51"

export const sections = () => {
  return [
    {
      label: "企业建站",
      pageId: "b3f02ecc-c535-54be-90a3-ccfe6b60a4a3",
      components: <SectionA11 />,
      background: "",
      enabled: false,
      sort: 1,
    },
    {
      label: "企业建站",
      pageId: "b3f02ecc-c535-54be-90a3-ccfe6b60a4a3",
      components: <SectionA12 />,
      background: "rgb(255,0,0)",
      enabled: false,
      sort: 2,
    },
    {
      label: "企业建站",
      pageId: "b3f02ecc-c535-54be-90a3-ccfe6b60a4a3",
      components: <SectionA13 />,
      background: "rgb(255,0,0)",
      enabled: false,
      sort: 3,
    },
    {
      label: "企业域名",
      pageId: "0e8bdd92-89bf-53e8-b5ba-e536e074d86f",
      components: <SectionB11 />,
      background: "rgb(255,255,255)",
      enabled: true,
      sort: 1,
    },
    {
      label: "网站备案",
      pageId: "b137a0d7-e94b-5999-9c05-b5f8c997c5d2",
      components: <SectionB21 />,
      background: "rgb(255,255,255)",
      enabled: true,
      sort: 1,
    },
    {
      label: "云空间",
      pageId: "6d2f51bf-a98c-528b-b443-58510b42abb2",
      components: <SectionB31 />,
      background: "rgb(255,255,255)",
      enabled: true,
      sort: 1,
    },
    {
      label: "企业邮箱",
      pageId: "1bf36264-527e-5e43-849d-c02474fb21d5",
      components: <SectionB41 />,
      background: "rgb(255,255,255)",
      enabled: true,
      sort: 1,
    },
    {
      label: "网站安全",
      pageId: "79fe9619-4b1b-59e8-b009-2657779ba9ee",
      components: <SectionB51 />,
      background: "rgb(255,255,255)",
      enabled: true,
      sort: 1,
    },
  ]
}
