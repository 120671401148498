import React from "react"

// gatsby ssr
import { graphql } from "gatsby"

// material-ui
import Stack from "@mui/material/Stack"

// project imports
import Container from "../components/Container"
import SliderMenu from "../components/Animation/SliderMenu"
import { sections } from "./template.sections"

// styles
import styled from "@emotion/styled"
import { header, hero, title } from "../assets/styles/template.module.css"

// ==============================|| Dynamic Site Pages ||============================== //

const DynSitePage = ({ data, pageContext }) => {
  const edges = data.allNavMenusJson.edges
  return (
    <React.Fragment>
      {edges.map(edge => {
        return edge.node.modules
          .filter(module =>
            module.pages.some(page => page.id === pageContext.pageId)
          )
          .map((module, i) => {
            return (
              <div key={i} className={header}>
                <div className={hero}>
                  <h3>{module.title}</h3>
                  <h5>{module.subtitle}</h5>
                </div>
                <div className={title}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {module.pages.map((page, i, arr) => {
                      return (
                        <SliderMenu
                          key={page.id}
                          title={page.title}
                          href={page.path}
                          isCurrent={page.id === pageContext.pageId}
                          divider={i + 1 !== arr.length}
                        />
                      )
                    })}
                  </Stack>
                </div>
              </div>
            )
          })
      })}

      <MixBox />

      {sections()
        .filter(
          section => section.pageId === pageContext.pageId && section.enabled
        )
        .sort((a, b) => (a.sort > b.sort ? 1 : a.sort === b.sort ? 0 : -1))
        .map((section, i) => {
          return (
            <Container bg={section?.background ? section?.background : "#fff"}>
              {section.components}
            </Container>
          )
        })}
    </React.Fragment>
  )
}

// ==============================|| STYLES COMPONENTS ||============================== //

const MixBox = styled(Container)`
  position: relative;
  height: 48px;
  background: white;
  z-index: 3;
`

// ==============================|| GRQPHQL DATA ||============================== //

export const query = graphql`
  query ($pageId: String!) {
    allNavMenusJson(
      filter: {
        modules: {
          elemMatch: { pages: { elemMatch: { id: { eq: $pageId } } } }
        }
      }
      sort: { fields: modules___pages___sort, order: ASC }
    ) {
      edges {
        node {
          modules {
            pages {
              id
              title
              path
            }
            bgcolor
            label
            title
            subtitle
          }
        }
      }
    }
  }
`

export default DynSitePage
